import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import Navigation from './Navigation';
import styled from 'styled-components';

const Holder = styled.header`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin-bottom: 12rem;
  color: ${props => props.invertHeader ? props.theme.colours.white : 'inherit'};
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin-bottom: 16rem;
    grid-template-columns: 7fr 5fr;
    align-items: flex-end;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    margin-bottom: 20rem;
  }
  h1 { margin: 0; }
`;
class Header extends Component {
    render() {
        return (
          <Holder invertHeader={this.props.invertHeader}>
            <h1>
              <Link to="/">
                {this.props.siteTitle}
              </Link>
            </h1>
            <Navigation/>
          </Holder>
        )
    }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
  invertHeader: false,
};

export default Header
