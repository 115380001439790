import React, { Component } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Nav = styled.nav`
  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    margin: 0;
    li {
      margin-right: 0.5rem;
      &:after {
        content: '/';
        padding-left: 0.5rem;
      }
      &:last-child {
        margin-right: 0;
        &:after { display: none; }
      }
      a {
        text-decoration: none;
      }
    }
  }
`;

class Navigation extends Component {
    render() {
        return (
            <Nav>
              <ul>
                <li><Link to="/projects/">Projects</Link></li>
                <li><Link to="/work/">Work</Link></li>
                <li><Link to="/texts/">Texts</Link></li>
                <li><Link to="/cv/">CV</Link></li>
                <li><Link to="/contact/">Contact</Link></li>
              </ul>
            </Nav>
        )
    }
}

export default Navigation;