import React, { Component } from 'react';

class Footer extends Component {
  render() {
    if(!this.props.hideFooter) {
      return (
        <footer>
          <p>
            <small>© {new Date().getFullYear()}, Trent Whitehead</small>
          </p>
        </footer>
      )
    } else {
      return null;
    }
  }
}

Footer.defaultProps = {
  hideFooter: false,
};

export default Footer;